import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      { path: "/dashboard",name: "dashboard",component: () => import("@/views/Dashboard.vue"),},
      { path: "/dossierDetail/:id",  name: 'dossier-detail', component: ()=> import("@/components/dossierStatus/dossier/dossierDetail.vue")},
      { path: "/expirations",  name: 'expiration-document', component: ()=> import("@/components/documentsExpiration/expirations.vue")},
      { path: "/builder",name: "builder",component: () => import("@/views/Builder.vue"),},
      { path: "/crafted/pages/profile",name: "profile",component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          { path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
          
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/apps/subscriptions/getting-started",
        name: "apps-subscriptions-getting-started",
        component: () =>
          import("@/views/apps/subscriptions/GettingStarted.vue"),
      },
      {
        path: "/apps/subscriptions/subscription-list",
        name: "apps-subscriptions-subscription-list",
        component: () =>
          import("@/views/apps/subscriptions/SubscriptionList.vue"),
      },
      {
        path: "/apps/subscriptions/add-subscription",
        name: "apps-subscriptions-add-subscription",
        component: () =>
          import("@/views/apps/subscriptions/AddSubscription.vue"),
      },
      {
        path: "/apps/subscriptions/view-subscription",
        name: "apps-subscriptions-view-subscription",
        component: () =>
          import("@/views/apps/subscriptions/ViewSubscription.vue"),
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
          import("@/views/crafted/modals/general/InviteFriends.vue"),
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/products",
    redirect: "/products",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/products",
        name: "allProducts",
        component: () => import("@/components/products/allProducts.vue"),
      },
      {
        path: "/products/:id",
        name: "editProduct",
        component: () => import("@/components/products/editProduct.vue"),
      },
      
      //{ path: 'orders', name: 'orders', component: ()=> import('@/views/orders/orders.vue')},
      //{ path: 'order/:id', name: 'detailOrder', component: ()=> import('@/views/orders/detailOrder.vue')},
      {
        path: "/createProduct",
        name: "createProduct",
        component: () => import("@/components/products/createProduct.vue"),
      },
      {
        path: "translateProduct/:id",
        name: "translateProduct",
        component: () => import("@/components/products/translateProduct.vue"),
      },
      //{ path: 'languages', name: 'languages', component: ()=> import('@/views/languages.vue')},
    ],
  },
  {
    path: "/links",
    redirect:"/links",
    component:()=>import("@/layout/Layout.vue"),
    children:[
      { path: "/links",  name: 'links-for-homepage-app', component: ()=> import("@/components/links/allLinks.vue")},
    ]
  },
  {
    path: "/dossierStatus",
    redirect:"/dossierStatus",
    component:()=>import("@/layout/Layout.vue"),
    children:[
      { path: "/dossierStatus",  name: 'dossier-status', component: ()=> import("@/components/dossierStatus/dossierStatus.vue")},
      
    ]
  },
  { path: '/languages',
    redirect:"/languages",
    component:()=>import("@/layout/Layout.vue"),
    children:[
      { path: "/languages",  name: 'languages', component: ()=> import('@/components/languages/allLanguages.vue')},
    ]
  },
  { path: '/clients',
    redirect:"/clients",
    component:()=>import("@/layout/Layout.vue"),
    children:[
      { path: "/clients",  name: 'clients', component: ()=> import('@/components/clients/allClients.vue')},
      { path: "/editDataClient/:id",  name: 'editClient', component: ()=> import('@/components/clients/editDataClient.vue')},
    ]
  },
  { path: '/orders',
    redirect:"/orders",
    component:()=>import("@/layout/Layout.vue"),
    children:[
      { path: "/orders",  name: 'orders', component: ()=> import('@/components/orders/allOrders.vue')},
      { path: "/infoOrder/:id",  name: 'infoOrder', component: ()=> import('@/components/orders/infoOrder.vue')},
    ]
  },
  { path: '/forms',
    redirect:"/forms",
    component:()=>import("@/layout/Layout.vue"),
    children:[
      { path: "/forms",  name: 'forms', component: ()=> import('@/components/forms/allForms.vue')},
      { path: "/createForm",  name: 'createForm', component: ()=> import('@/components/forms/createForm.vue')},
      { path: "/editForm/:id",  name: 'editForm', component: ()=> import('@/components/forms/editForm.vue')},
      { path: "/translateForm/:id",  name: 'translateForm', component: ()=> import('@/components/forms/translateForm.vue')},
    ]
  },
  { path: '/employees',
    redirect:"/employees",
    component:()=>import("@/layout/Layout.vue"),
    children:[
      { path: "/employees",  name: 'employees', component: ()=> import('@/components/employees/allEmployees.vue')},
    ]
  },
  { path: '/attributes',
    redirect:"/attributes",
    component:()=>import("@/layout/Layout.vue"),
    children:[
      { path: "/attributes",  name: 'attributes', component: ()=> import('@/components/attributes/allAttributes.vue')},
      { path: '/attributeTranslate/:id', name: 'attributeTranslate', component: ()=> import('@/components/attributes/attributeTranslate.vue')},
      { path: '/optionsAttribute/:id', name: 'optionsAttribute', component: ()=> import('@/components/attributes/options/optionsAttribute.vue')},
      { path: '/optionAttributeTranslate/:id', name: 'optionAttributeTranslate', component: ()=> import('@/components/attributes/options/optionAttributeTranslate.vue')},
    ]
  },
  { path: '/categories',
    redirect:"/categories",
    component:()=>import("@/layout/Layout.vue"),
    children:[
      { path: "/categories",  name: 'categories', component: ()=> import('@/components/categories/allCategories.vue')},
      { path: '/editCategory/:id', name: 'category', component: ()=> import('@/components/categories/editCategory.vue')},
      { path: '/categoryTranslate/:id', name: 'translate.category', component: ()=> import('@/components/categories/translateCategory.vue')},
    ]
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
